// import React, { useEffect, useState } from "react";
import { BsCheck2Square } from "react-icons/bs";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
// import { useLocation } from "react-router-dom";
import * as Scroll from "react-scroll";
// import { CourseModal } from "./CourseModal";

function CoursesBanner({ course }) {
  // const location = useLocation();
  // const [selfPace, setSelfPace] = useState(false);
  // useEffect(() => {
  //   if (location.pathname.includes("full-stack-web-development-nodejs")) {
  //     setSelfPace(true);
  //   } else {
  //     setSelfPace(false);
  //   }
  // }, [location]);
  return (
    <div className="course-banner">
      <div className="course-banner__container container-banner">
        <div className="course-banner__section">
          <div className="course-banner__left">
            <div className="cb__left-subtitle">
              {/* <span>{course.banner_sub_text}</span> */}
              <span dangerouslySetInnerHTML={{ __html: course.banner_sub_text }} />
            </div>
            <div className="cb__left-header">
              <h2>{course.name}</h2>
              <p>{course.banner_text}</p>
              <div className="cb__left-links">
                {/*  {selfPace ? (
                  <a href="https://apply.reworkacademy.co/" className="link__btn link__btn-bg-blue">
                    Apply Now (Physical Class)
                    <IoChevronForwardCircleOutline className="link__btn-icon" />
                  </a>
                ) : (
                  <a href="https://apply.reworkacademy.co/" className="link__btn link__btn-bg-blue">
                    Apply Now
                    <IoChevronForwardCircleOutline className="link__btn-icon" />
                  </a>
                )} */}
                <a href="https://apply.reworkacademy.co/" className="link__btn link__btn-bg-blue">
                  Apply Now
                  <IoChevronForwardCircleOutline className="link__btn-icon" />
                </a>
                <Scroll.Link to="classOption" smooth={true} offset={-80} duration={500}>
                  <button className="link__btn link__btn-outline-blue">
                    Class & Payment Option
                    <IoChevronForwardCircleOutline className="link__btn-icon" />
                  </button>
                </Scroll.Link>
              </div>
              {/*  {selfPace ? (
              <div className="course_banner--self-pace-btn">
                <a href="https://online.reworkacademy.co/" target="_blank" rel="noreferrer" className="link__btn link__btn-bg-orange">
                  Join Online(Self-Paced)
                </a>
              </div>
              ) : null} */}
            </div>
            {/* {selfPace ? <CourseModal /> : null} */}
            <div className="cb__left-nsd">
              <p>
                <strong>Next Start Date: </strong>
                <span>{course.start_date}</span>
              </p>
            </div>
          </div>
          <div className="course-banner__right">
            <img src={course.icon} crossOrigin="anonymous" alt={course.name} />
          </div>
        </div>
      </div>
      {course.learning_mode.length ? (
        <div className="course-banner__learning_modes">
          <div className="course-banner__learning_modes_sections">
            <div className="course-banner__learning_mode_section">
              {course.learning_mode.map((learning, i) => (
                <div className="course-banner__learning_mode_sects" key={i}>
                  <BsCheck2Square />
                  <h4>{learning.title}</h4>
                  <p>{learning.descp}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CoursesBanner;
