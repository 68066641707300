import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AdmissionProcess from "../Components/course/AdmissionProcess";
import ApplyCourse from "../Components/course/ApplyCourse";
import ClassOption from "../Components/course/ClassOption";
import CourseModules from "../Components/course/CourseModules";
import CoursesBanner from "../Components/course/CoursesBanner";
import Tuition from "../Components/course/Tuition";
import "../Assets/Styles/Course.css";
import { CourseLoading } from "../Layouts/CourseLoading";
import WhyChooseUs from "../Components/course/WhyChooseUs";

function Course() {
  const { route } = useParams();
  const url = process.env.REACT_APP_URL;
  const [course, setCourse] = useState({});
  const [courseResp, setCourseResp] = useState("");
  const getCourse = useCallback(async () => {
    try {
      setCourseResp(<CourseLoading />);
      const resp = await axios.get(`${url}/courses/manager/${route}/route`);
      const data = await resp.data;
      setCourseResp("");
      setCourse(data);
    } catch (error) {
      setCourseResp("");
      setCourse([]);
    }
  }, [url, route]);

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  return (
    <div className="wrapper">
      {course.id && course.active ? (
        <div className="courses">
          <CoursesBanner course={course} />
          <CourseModules course={course} />
          <div id="classOption">
            <ClassOption course={course} />
          </div>
          {course.has_assessment ? <AdmissionProcess /> : null}
          {course.name === "Digital Office Tools" ? <WhyChooseUs /> : null}
          <div id="tuition">
            <Tuition course={course} />
          </div>
          <ApplyCourse title={course.name} />
        </div>
      ) : courseResp ? (
        courseResp
      ) : (
        <div className="err-space">
          <h2 className="networkErr">Course Not Available</h2>
          <Link to="/courses" className="link__btn link__btn-outline-blue">
            View courses
          </Link>
        </div>
      )}
    </div>
  );
}

export default Course;
