const WhyChooseUs = () => {
  return (
    <div className="admission-process why_choose_us">
      <div className="adm__container containers">
        <div className="adm__section-header">
          <h3>Why Choose us</h3>
        </div>
        <div className="adm__section">
          <div className="adm__sect-box">
            <h3>Experienced Instructors</h3>
            <p>with industry expertise.</p>
          </div>
          <div className="adm__sect-box">
            <h3>Hands-On Projects</h3>
            <p>for real-world application.</p>
          </div>
          <div className="adm__sect-box">
            <h3>Certification</h3>
            <p>upon completion to showcase your skills</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyChooseUs;
